.new-game-container {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
}

.new-game-button {
  background: none;
  margin-top: 1.25em;
  transition: 0.5s;
  font-weight: bold;
  border: none;
  font-size: 16pt;
}

.new-game-button:hover {
  text-decoration: underline;
}
