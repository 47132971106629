.grid-grid {
  display: grid;
  color: ghostwhite;
  margin-top: 5%;
  font-weight: bold;
  transition: 0.5s;
}

.grid-grid > div {
  width: 100%;
  height: 100%;
}

.grid-footer {
  display: flex;
  justify-content: center;
}

.grid-hint {
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 100%;
}

.tile {
  border: solid ghostwhite 1px;
  transition: 0.5s;
}

.hint-solved {
  color: gray;
}

.tile-unselected:not(.tile-solved) {
  cursor: pointer;
}

.tile-unselected:hover {
  background-color: ghostwhite;
}

.tile-selected {
  background-color: ghostwhite;
}

.tile-selected.tile-solved {
  background: ghostwhite;
}

.tile-unselected.tile-solved {
  opacity: 0.3;
  background: lightgray
    repeating-linear-gradient(
      45deg,
      #000000,
      #000000 2.5px,
      ghostwhite 2.5px,
      ghostwhite 22.5px
    );
}
