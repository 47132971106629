:root {
  color-scheme: dark;
  scrollbar-color: white black;
}

body,
html {
  overflow-x: hidden;
}

body {
  -webkit-overflow-scrolling: touch;

  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #2234ae;
  background-image: linear-gradient(315deg, #2234ae 0%, #191714 20%);
  background-attachment: fixed;
  height: 100vh;
}


.grid-grid {
  display: grid;
  color: ghostwhite;
  margin-top: 5%;
  font-weight: bold;
  transition: 0.5s;
}

.grid-grid > div {
  width: 100%;
  height: 100%;
}

.grid-footer {
  display: flex;
  justify-content: center;
}

.grid-hint {
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 100%;
}

.tile {
  border: solid ghostwhite 1px;
  transition: 0.5s;
}

.hint-solved {
  color: gray;
}

.tile-unselected:not(.tile-solved) {
  cursor: pointer;
}

.tile-unselected:hover {
  background-color: ghostwhite;
}

.tile-selected {
  background-color: ghostwhite;
}

.tile-selected.tile-solved {
  background: ghostwhite;
}

.tile-unselected.tile-solved {
  opacity: 0.3;
  background: lightgray
    repeating-linear-gradient(
      45deg,
      #000000,
      #000000 2.5px,
      ghostwhite 2.5px,
      ghostwhite 22.5px
    );
}

.error-field {
  text-align: center;
  color: red;
  font-weight: bold;
  margin-top: 1.25em;
}

.new-game-container {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
}

.new-game-button {
  background: none;
  margin-top: 1.25em;
  transition: 0.5s;
  font-weight: bold;
  border: none;
  font-size: 16pt;
}

.new-game-button:hover {
  text-decoration: underline;
}

.success-field {
  margin-top: 20px;
  text-align: center;
  color: greenyellow;
  font-weight: bold;
}

